* {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  height: 100%
}

body {
  display: -ms-flexbox;
  display: flex;
}

h2 {
  padding-bottom: 15px;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

header {
  background-color: #282c34;
  padding: 16px !important;
}

footer {
  background: #d3d3d3;
}

footer p {
  padding-top: 20px;
  padding-bottom: 6px;
}

form {
  /* area where body proper shown */
  padding-left: 14px;
}

.captcha {
  padding: 10px 0 20px;
  min-height: 78px;
}

.content {
  max-width: 1024px;
  margin: auto;
  padding: 40px 0 50px 2px;
}

/* h2 {margin-left: -8px;} */
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

p {
  font-size: 1.1em;
}