.profile-picture {
    width: 200px;
    height: 200px;
    padding: 12px;
    margin: 10px 0 10px 10px;
    border: 2px solid #d3d3d3;
    float: right;
}

.badge-box {
    padding-top: 40px;
    float: left;
    margin-right: 14px;
}


.badge-box:after {
    clear: both;
}

.badge-item {
    width: 160px;
    height: 180px;
    display: inline-block;
}