.react-logo {
  display: inline-block;
  background-image: url("../assets/img/react-logo.svg");
  background-repeat: no-repeat;
  width:22px;
  height:22px;
}

  .azure-logo {
    display: inline-block;
    background-image: url("../assets/img/azure-logo.svg");
    background-repeat: no-repeat;
    background-size: 22px 22px;
    width:22px;
    height:22px;
  }

  .bootstrap-logo {
    display: inline-block;
    background-image: url("../assets/img/bootstrap-logo.svg");
    background-repeat: no-repeat;
    background-size: 22px 22px;
    width:22px;
    height:22px;
  }

  .link-unstyled {
    color: inherit;
    text-decoration: none !important;
  }

  .link-unstyled:hover {
    color: inherit;
    text-decoration: none;
  }